/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
// import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, titleDesc, fbDes, twitDesc, seoLink, ogImage }) {
  /* const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  ) */

  // const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={``}
      meta={[
        {
          name: `robots`,
          content: `noindex, nofollow`,
        },
        {
          name: `Language`,
          content: `English`,
        },
        {
          name: `Copyright`,
          content: `Fuse Classroom`,
        },
        {
          name: `Cache-Control`,
          content: `no-cache, no-store, must-revalidate`,
        },
        {
          name: `Pragma`,
          content: `no-cache`,
        },
        {
          name: `Expires`,
          content: `0`,
        },
        {
          name: `description`,
          content: titleDesc,
        },
        // social
        // facebook
        {
          property: `fb:app_id`,
          content: `227893031220193`,
        },
        {
          property: `og:url`,
          content: seoLink,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:description`,
          content: fbDes,
        },
        {
          property: `og:image:type`,
          content: `image/jpeg`,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          property: `og:image:width`,
          content: `600`,
        },
        {
          property: `og:image:height`,
          content: `315`,
        },
        // twitter
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:url`,
          content: seoLink,
        },
        {
          name: `twitter:creator`,
          content: "@fuse.ai",
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: twitDesc,
        },
        {
          name: `twitter:image`,
          content: ogImage,
        },
        {
          name: `twitter:image:alt`,
          content: `AI Course`,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,

  titleDesc: ``,
  fbDes: ``,
  twitDesc: ``,
  seoLink: ``,
  ogImage: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,

  titleDesc: PropTypes.string,
  fbDes: PropTypes.string,
  twitDesc: PropTypes.string,
  seoLink: PropTypes.string,
  ogImage: PropTypes.string,
}

export default SEO
